var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "p-4",
    attrs: {
      id: "restart-prescription",
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      "no-stacking": ""
    },
    on: {
      exit: _vm.onModalExit
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("b-row", {
    staticClass: "mb-4",
    attrs: {
      "align-h": "between"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "10"
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon_logo_violet.svg"),
      alt: ""
    }
  })]), _c("b-col", {
    staticClass: "text-right"
  }, [_c("a", {
    staticClass: "tw-text-eclipse",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "x-circle",
      "font-scale": "1.5"
    }
  })], 1)])], 1), _c("b-alert", {
    attrs: {
      show: _vm.alert.show,
      variant: "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-row", [_c("b-col", {
    attrs: {
      cols: "9"
    }
  }, [_c("h2", {
    staticClass: "heading"
  }, [_vm._v(" Restart Prescription ")])])], 1), _vm.processing ? [_c("div", {
    staticClass: "tw-text-center tw-mb-4"
  }, [_c("b-spinner", {
    attrs: {
      variant: "primary"
    }
  })], 1), _c("h3", {
    staticClass: "tw-text-center"
  }, [_vm._v(" Processing payment ")]), _c("p", {
    staticClass: "mb-0 tw-italic tw-text-center"
  }, [_vm._v(" Exiting will not stop the payment processing. Please remain on the screen while this completes. ")]), _c("b-row", {
    staticClass: "mt-3"
  }, [_c("b-col", [_c("b-row", [_c("b-col", [_c("b-button", {
    attrs: {
      variant: "outline-primary",
      block: "",
      pill: ""
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_vm._v(" Exit ")])], 1)], 1)], 1)], 1)] : [_vm.has90DayError ? _c("b-row", [_c("b-col", [_c("h3", [_vm._v(" Restart and recharge patient for prescription cancelled by Stripe ")]), _c("div", [_c("p", {
    staticClass: "mb-0"
  }, [_vm._v(" Estrogen & Progesterone ")]), _c("p", [_vm._v(" Patient has a Progesterone prescription cancelled by Stripe within the last 90 days. Restarting this Estrogen prescription will first restart and charge the following prescription before proceeding. ")])]), _vm.progesteronePrescription ? _c("div", [_c("b-card", {
    class: _vm.getCSSClasses(_vm.progesteronePrescription),
    attrs: {
      "border-variant": "light"
    }
  }, [_c("div", {
    staticClass: "tw-flex tw-items-start tw-mb-3"
  }, [_c("div", {
    staticClass: "tw-flex-1"
  }, [_c("h2", {
    staticClass: "tw-mb-1 tw-font-atten tw-text-xl tw-text-[#19191C]"
  }, [_vm._v(" " + _vm._s(_vm.progesteronePrescription.medicinename) + " | " + _vm._s(_vm.progesteronePrescription.dosage) + " ")]), _c("h4", {
    staticClass: "card-status mb-0 tw-text-base tw-leading-snug"
  }, [_vm._v(" " + _vm._s(_vm.getRefillString(_vm.progesteronePrescription)) + " ")]), _c("h4", {
    staticClass: "tw-text-[#828282] tw-text-base tw-leading-snug mb-0"
  }, [_vm._v(" " + _vm._s(_vm.progesteronePrescription.unit) + " ")])]), _c("div", {
    staticClass: "tw-flex-1 tw-text-right tw-pl-2"
  }, [_c("p", {
    staticClass: "text-primary card-status tw-mb-0 tw-capitalize tw-flex tw-items-center tw-justify-end"
  }, [_c("span"), _c("span", [_vm._v(" " + _vm._s(_vm.progesteronePrescription.prescriptionstatus) + " ")])])])]), _c("b-row", {
    attrs: {
      "align-v": "end"
    }
  }, [_vm.progesteronePrescription.timestampprescibed ? _c("b-col", [_c("span", {
    staticClass: "tw-text-[#828282] tw-text-[14px]"
  }, [_vm._v(" Prescribed ")]), _c("br"), _c("span", {
    staticClass: "tw-text-[14px]"
  }, [_vm._v(" " + _vm._s(_vm.progesteronePrescription.timestampprescibed ? _vm.parseUnixDate(_vm.progesteronePrescription.timestampprescibed, "MM/dd/yy") : "-") + " ")])]) : _vm._e(), _vm.progesteronePrescription.actualenddate ? _c("b-col", [_c("span", {
    staticClass: "tw-text-[#828282] tw-text-[14px]"
  }, [_vm._v(" Last Refill Ends ")]), _c("br"), _c("span", {
    staticClass: "tw-text-[14px]"
  }, [_vm._v(" " + _vm._s(_vm.progesteronePrescription.actualenddate ? _vm.parseUnixDate(_vm.progesteronePrescription.actualenddate, "MM/dd/yy") : "-") + " ")])]) : _vm._e(), _vm.progesteronePrescription.nextshipmentdate ? _c("b-col", [_c("span", {
    staticClass: "tw-text-[#828282] tw-text-[14px]"
  }, [_vm._v(" Next Refill ")]), _c("br"), _c("span", {
    staticClass: "tw-text-[14px]"
  }, [_vm._v(" " + _vm._s(_vm.progesteronePrescription.nextshipmentdate && _vm.progesteronePrescription.transactiontype !== "one_time" ? _vm.parseUnixDate(_vm.progesteronePrescription.nextshipmentdate, "MM/dd/yy") : "-") + " ")])]) : _vm._e()], 1)], 1)], 1) : _vm._e()])], 1) : _c("b-row", [_c("b-col", {
    attrs: {
      cols: "12"
    }
  }, [_c("h3", [_vm._v(" This will restart the prescription acting to resolve the failed payment. If the payment fails, nothing will occur and this can be performed again. If payment is successful, the prescription status will be instantly updated. ")])])], 1), _c("b-row", {
    staticClass: "mt-3"
  }, [_c("b-col", [_c("b-row", [_c("b-col", [_c("b-button", {
    attrs: {
      variant: "primary",
      block: "",
      pill: "",
      disabled: _vm.buttonDisabled
    },
    on: {
      click: _vm.restartPrescription
    }
  }, [_vm.buttonDisabled ? _c("b-spinner", {
    attrs: {
      small: ""
    }
  }) : _c("span", [_vm._v("Restart")])], 1), _c("b-button", {
    attrs: {
      variant: "outline-primary",
      block: "",
      pill: "",
      disabled: _vm.buttonDisabled
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_vm._v(" Nevermind ")])], 1)], 1)], 1)], 1)]], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };